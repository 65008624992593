import { observable, action, computed, toJS } from "mobx";
import ModalStore from "@src/stores/Component/ModalStore";

class ImageDialogStore extends ModalStore {
	constructor(rootStore, staticContext) {
		super({
			rootStore: rootStore,
		});
	}

	@observable _reviewId = "";
	@computed
	get reviewId() {
		return toJS(this._reviewId);
	}

	@observable _media = [];
	@computed
	get media() {
		return toJS(this._media);
	}

	@observable _selectedIndex = -1;
	@computed
	get selectedIndex() {
		return toJS(this._selectedIndex);
	}

	@computed
	get currentMedia() {
		return toJS(this.media[this.selectedIndex]);
	}

	get show() {
		return super.show;
	}

	onClose(clearData) {
		super.onClose(clearData);
	}

	init(reviewId, media, selectedIndex) {
		this._reviewId = reviewId;
		this._media = media;
		this._selectedIndex = selectedIndex;
		this.showModal = true;
	}

	@action
	reset() {
		this._reviewId = "";
		this._media = [];
		this._selectedIndex = -1;
	}

	@action
	switchMedia(direction) {
		if (this.selectedIndex === 0 && direction === -1) return;
		if (this.selectedIndex === this.media.length - 1 && direction === 1) return;

		this._selectedIndex = this.selectedIndex + direction;
	}
}

export default ImageDialogStore;
