import { observable, action, toJS, computed } from "mobx";
import moment from "moment";

import rootService from "../@data/service";

const { createReviewCommentByProviderToken } = rootService;

class ReviewItemStore {
	noPicName = "no_pic.png";
	@observable _responding = {};

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
	}

	@action
	newResponse(_id) {
		const newResponses = Object.assign({}, this._responding);
		newResponses[_id] = "";
		this._responding = newResponses;
	}

	@action
	cancelResponse(_id) {
		this.clearFromResponding(_id);
	}

	@action
	updateResponse(_id, e) {
		const newResponses = Object.assign({}, this._responding);
		newResponses[_id] = e.target.value;
		this._responding = newResponses;
	}

	@action
	async submitResponse(_id) {
		try {
			const response = this.responding[_id];

			const comment = {
				message: response,
				review: _id,
				from_provider: true,
				from_client: false,
			};

			const { error } = await createReviewCommentByProviderToken({
				record: comment,
			});

			if (!!error) throw error;

			this.clearFromResponding(_id);

			return comment;
		} catch (err) {
			throw err;
		}
	}

	@computed
	get responding() {
		return toJS(this._responding);
	}

	publishedDate(dateString) {
		const date = new Date(dateString);
		const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
		return moment(date.getTime() + timezoneOffset).format("MMMM DD, YYYY");
	}

	clearFromResponding(_id) {
		const newResponses = Object.assign({}, this._responding);
		delete newResponses[_id];
		this._responding = newResponses;
	}

	hasMedia(media) {
		return !!media && !!media.length;
	}

	clientImg(client) {
		if (!!client && !!client.image) {
			const splitSrc = client.image.value.split("/");

			return splitSrc[splitSrc.length - 1] === this.noPicName
				? null
				: client.image.value;
		}
		return null;
	}
}

export default ReviewItemStore;
