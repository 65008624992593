export default (theme) => ({
	reviewBox: {
		//paddingBottom: 16,
	},
	reviewAuthor: {
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 2,
		fontSize: 16,
		textTransform: "capitalize",
	},
	reviewBody: {
		width: "100%",
		marginTop: 12,
		marginBottom: 12,
		fontSize: 16,
		lineHeight: 1.6,
		[theme.breakpoints.down("xs")]: {
			//fontSize: 14,
		},
	},
	reviewShowMore: {
		color: theme.palette.secondary.main,
		textDecoration: "underline",
		fontWeight: 600,
	},
	reviewDate: {
		color: theme.palette.grey[600],
		fontSize: 14,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	reviewHiredIcon: {
		fontSize: 18,
		marginRight: 4,
	},
	reviewRespond: {
		marginTop: 16,
	},
	reviewAlert: {
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.grey[100],
		lineHeight: 1.6,
	},
	reviewFlex: {
		display: "flex",
	},
	reviewRespondButtonBox: {
		marginTop: 16,
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	reviewRespondButtonSpacing: {
		marginRight: 12,
	},
	reviewSpacer: {
		marginLeft: 8,
		marginRight: 8,
	},
	reviewReplyTitle: {
		fontWeight: 600,
	},
	reviewPhotos: {
		"overflowX": "hidden",
		"-webkit-overflow-scrolling": "touch",
		"marginBottom": 16,
	},
	reviewPhotosSpacing: {
		marginRight: 8,
	},
	reviewPhotosImg: {
		"cursor": "pointer",
		"width": 64,
		"height": 64,
		"overflow": "hidden",
		"&:hover": {
			opacity: "0.8",
		},
	},
	reviewMediaContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
		cursor: "pointer",
	},
	reviewMediaIconBox: {
		position: "absolute",
		zIndex: 1,
		display: "flex",
	},
	reviewMediaIcon: {
		opacity: 0.6,
	},
	//
	flexRowCenter: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatar: {
		width: 40,
		height: 40,
		fontSize: 14,
		fontWeight: 600,
		color: "#222",
	},
	authorBox: {
		marginLeft: 8,
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	avatarBlue: {
		backgroundColor: "#0e6be6",
		color: "#FFF",
	},
	avatarRed: {
		backgroundColor: "#c45b14",
		color: "#FFF",
	},
	avatarGreen: {
		backgroundColor: "#27c668",
		color: "#FFF",
	},
	avatarYellow: {
		backgroundColor: "#19ada9",
		color: "#FFF",
	},
	avatarPurple: {
		backgroundColor: "#5b2ba3",
		color: "#FFF",
	},
	avatarOrange: {
		backgroundColor: "#da8721",
		color: "#FFF",
	},
	// avatarBlue: {
	// 	backgroundColor: "rgb(16, 124, 255 , 16%)",
	// },
	// avatarRed: {
	// 	backgroundColor: "rgb(227, 105, 23 , 16%)",
	// },
	// avatarGreen: {
	// 	backgroundColor: "rgb(45, 229, 120 , 16%)",
	// },
	// avatarYellow: {
	// 	backgroundColor: "rgb(28, 200, 196 , 16%)",
	// },
	// avatarPurple: {
	// 	backgroundColor: "rgb(105, 49, 189 , 16%)",
	// },
	// avatarOrange: {
	// 	backgroundColor: "rgb(252, 156, 38 , 16%)",
	// },
});
