export default (theme) => ({
	mediaPaper: {
		verticalAlign: "top",
		background: "rgb(0 0 0 / 50%)",
		boxShadow: "unset",
	},
	mediaDialogHeader: {
		display: "flex",
		alignItems: "center",
		flex: "0 0 auto",
		justifyContent: "space-between",
		position: "relative",
		margin: "40px 40px 20px",
		color: "#FFF",
		padding: 0,
		zIndex: 1,

		[theme.breakpoints.down("xs")]: {
			margin: "24px 24px 12px",
		},
	},

	outlineButton: {
		"color": "#FFF",
		"border": "2px solid #FFF",
		"padding": 10,
		"&:hover": {
			background: "rgb(74, 74, 74)",
		},
	},
	whiteButton: {
		"background": "#FFF",
		"transition": theme.transitions.create(["all"], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.standard,
		}),
		"&:hover": {
			transform: "scale(1.1)",
			background: "#FFF",
		},
	},
	mediaContainerBox: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
			top: "unset",
			bottom: 24,
		},
	},
	mediaContainerPic: {
		"height": "100%",
		"width": "100%",
		"objectFit": "contain",
		"userSelect": "none",
		"userDrag": "none",
		"-webkit-user-drag": "none",
		"-moz-user-select": "none",
		"-webkit-user-select": "none",
		"-ms-user-select": "none",
	},
	mediaContainerVideo: {
		margin: "0 auto",
		maxWidth: 950,
	},
	mediaContainerPicBox: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
		top: 0,
		height: "100%",
		width: "100%",
	},
	mediaContainerNav: {
		display: "flex",
		justifyContent: "space-between",
		marginLeft: "calc(2% + 19px)",
		marginRight: "calc(2% + 19px)",
	},
	mobileStepper: {
		"background": "transparent !important",
		"userSelect": "none",
		"-webkit-user-select": "none",
		"-webkit-touch-callout": "none",
	},
	mediaItem: {
		display: "flex",
		verticalAlign: "bottom",
		width: "100%",
		height: "100%",
		minHeight: 1,
	},
	mediaCaption: {
		"flex": "0 0 auto",
		"padding": 24,
		"color": "#FFFFFF",
		"textAlign": "center",
		"userSelect": "none",
		"-webkit-user-select": "none",
		"-webkit-touch-callout": "none",
	},
});
