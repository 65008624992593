import gql from "graphql-tag";

export const createReviewCommentByProviderTokenMutationFragment = gql`
	fragment createReviewCommentByProviderTokenMutationFragment on CreateOneReviewCommentPayload {
		recordId
		error {
			... on ValidationError {
				message
				errors {
					message
					path
				}
			}
		}
	}
`;
