import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";

import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";

import { Alert, AlertTitle } from "@material-ui/lab";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import { observer } from "mobx-react-lite";
import LoadingButton from "@components/Global/Custom/Button";
import { compose } from "recompose";
import withStores from "@framework/src/libs/withStores";

import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";

import { stores, styles } from "./.config";
import withStyles from "@framework/src/libs/withStyles";

import ShowMoreText from "react-show-more-text";

const { BRAND_NAME } = process.env;

const ReviewItem = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(
	({
		review,
		displayReplyName = true,
		onSubmitResponse,
		showReplyButton = true,
		displayAuthorName = true,
	}) => {
		const classes = useStyles("ReviewItem");
		const stores = useStores();
		const { reviewItemStore: store } = stores;

		const handleRespond = () => {
			store.newResponse(review._id);
		};

		const handleCancel = () => {
			store.cancelResponse(review._id);
		};

		const handleChange = (e) => {
			store.updateResponse(review._id, e);
		};

		const handleSubmit = async () => {
			const reply = await store.submitResponse(review._id);
			onSubmitResponse(review._id, reply);
		};

		const handleViewMedia = (reviewId, media, selectedIndex) => (e) => {
			stores.imageDialogStore.init(reviewId, media, selectedIndex);
		};

		//

		const colorGroups = {
			blue: ["A", "G", "M", "S", "Y"],
			red: ["B", "H", "N", "T", "Z"],
			green: ["C", "I", "O", "U"],
			yellow: ["D", "J", "P", "V"],
			purple: ["E", "K", "Q", "W"],
			orange: ["F", "L", "R", "X"],
		};

		const firstLetter = (review.author?.charAt(0) || "A").toUpperCase();

		let colorClass = ""; // Default class if needed
		for (const [color, letters] of Object.entries(colorGroups)) {
			if (letters.includes(firstLetter)) {
				colorClass = `avatar${color.charAt(0).toUpperCase() + color.slice(1)}`; // Construct the class name
				break;
			}
		}

		return (
			<div className={classes.reviewBox}>
				<div className={classes.flexRowCenter}>
					<Avatar
						// className={
						// 	showReplyButton
						// 		? `${classes.avatar} ${classes[colorClass]}`
						// 		: `${classes.avatar} `
						// }
						className={classes.avatar}
						variant="rounded"
						alt={"Photo of " + review.author}
						src={store.clientImg(review.client)}
						imgProps={{ loading: "lazy", decoding: "async" }}
					>
						{(review.author || "Unknown")
							.split(" ")
							.map((name) => name?.charAt(0) || "")
							.join("")
							.toUpperCase()}
					</Avatar>

					<div className={classes.authorBox}>
						<h3 className={classes.reviewAuthor}>
							{displayAuthorName ? review.author : review.provider.company_name}
						</h3>
						<Rating
							name="half-rating-read"
							value={review.rating}
							precision={0.5}
							readOnly
							size="small"
						/>
					</div>
				</div>
				<ShowMoreText
					lines={4}
					more="Show more"
					less="Show less"
					className={classes.reviewBody}
					anchorClass={classes.reviewShowMore}
					expanded={false}
					truncatedEndingComponent={"... "}
				>
					<div className={classes.reviewBody}>
						{review.message.body
							.replace(/"/g, "")
							.replace(/'/g, "")
							.split("\n")
							.filter((el) => el !== "")
							.map((el, index) => (
								<div key={index}>{el}</div>
							))}
					</div>
				</ShowMoreText>
				{store.hasMedia(review.media) && (
					<div className={classes.reviewPhotos}>
						<div className={classes.reviewFlex}>
							{review.media.map((media, index) => {
								console.log({ media });
								return media.media_type === "video" ? (
									<div
										onClick={handleViewMedia(review._id, review.media, index)}
										className={classes.reviewMediaContainer}
										key={media.key}
									>
										<div className={classes.reviewMediaIconBox}>
											<PlayCircleOutlineOutlinedIcon
												fontSize={"large"}
												className={classes.reviewMediaIcon}
											/>
										</div>
										<Avatar
											variant={"rounded"}
											className={classes.reviewPhotosImg}
											alt={`review-media-${index + 1}`}
											title=""
											src={media.thumbnail}
											imgProps={{
												decoding: "async",
												loading: "lazy",
											}}
										/>
									</div>
								) : (
									<div className={classes.reviewPhotosSpacing} key={media.key}>
										<Avatar
											variant={"rounded"}
											className={classes.reviewPhotosImg}
											alt={`review-media-${index + 1}`}
											title=""
											src={media.value || media.picture?.value}
											onClick={handleViewMedia(review._id, review.media, index)}
											imgProps={{
												decoding: "async",
												loading: "lazy",
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
				<div className={classes.reviewDate}>
					{store.publishedDate(review.created_at)}
					{!!review.service && (
						<div>
							<span className={classes.reviewSpacer}>•</span>
							{review.service.name.ucFirst()}
						</div>
					)}
					{review.verified && (
						<div className={classes.reviewDate}>
							<span className={classes.reviewSpacer}>•</span>
							<CheckIcon color="primary" className={classes.reviewHiredIcon} />
							{`Hired on ${BRAND_NAME}`}
						</div>
					)}
				</div>

				<div>
					{store.responding.hasOwnProperty(review._id) && (
						<>
							<div className={classes.reviewRespond}>
								<TextField
									fullWidth
									style={{ backgroundColor: "#FFF" }}
									multiline={true}
									placeholder={`Respond to ${review.author}`}
									helperText={"Review responses cannot be edited after posting"}
									minRows={5}
									variant={"outlined"}
									onChange={handleChange}
								/>
							</div>

							<div className={classes.reviewRespondButtonBox}>
								<div className={classes.reviewRespondButtonSpacing}>
									<Button
										disableElevation
										variant={"outlined"}
										onClick={handleCancel}
									>
										Cancel
									</Button>
								</div>
								<div>
									<LoadingButton
										disableElevation
										variant={"contained"}
										color={"primary"}
										onClick={handleSubmit}
										label={"Submit"}
									/>
								</div>
							</div>
						</>
					)}
					{!!review.comments?.length &&
						review.comments.map((comment) => (
							<div className={classes.reviewRespond}>
								<Alert
									severity="info"
									icon={false}
									classes={{ root: classes.reviewAlert }}
								>
									<AlertTitle className={classes.reviewReplyTitle}>
										{displayReplyName
											? comment.from_provider
												? review.provider.company_name
												: review.author
											: "Your reply"}
									</AlertTitle>

									<ShowMoreText
										lines={4}
										more="Show more"
										less="Show less"
										//className={classes.reviewBody}
										anchorClass={classes.reviewShowMore}
										expanded={false}
										truncatedEndingComponent={"... "}
									>
										{comment.message
											.split("\n")
											.filter((el) => el !== "")
											.map((el, index) => (
												<div key={index}>{el}</div>
											))}
									</ShowMoreText>
								</Alert>
							</div>
						))}
					{showReplyButton &&
						!review.comments?.find((comment) => comment.from_provider) &&
						!store.responding.hasOwnProperty(review._id) && (
							<div className={classes.reviewRespond}>
								<Button
									disableElevation
									variant={"outlined"}
									onClick={handleRespond}
								>
									Respond
								</Button>
							</div>
						)}
				</div>
			</div>
		);
	}
);

ReviewItem.propTypes = {
	review: PropTypes.object.isRequired,
	displayReplyName: PropTypes.bool,
	onSubmitResponse: PropTypes.func,
	displayAuthorName: PropTypes.bool,
};

export default ReviewItem;
