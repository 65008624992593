import React from "react";
import { compose } from "recompose";
import { observer } from "mobx-react-lite";

import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";

import { stores, styles } from "./.config";
import ShareIcon from "@material-ui/icons/Share";

import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import shortid from "shortid";
//import { autoPlay } from "react-swipeable-views-utils";

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import LazyLoad from "@custom/LazyLoad";

const ImageDialog = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles("ImageDialog");
	const { imageDialogStore: store } = useStores();

	const handleSwitchMedia = (direction) => (e) => {
		resetVideo(store.selectedIndex);
		store.switchMedia(direction);
	};

	const handleStepChange = (step) => {
		resetVideo(store.selectedIndex);
		store._selectedIndex = step;
	};

	const handleKeydown = (e) => {
		const { key } = e;
		if (key === "ArrowLeft") store.switchMedia(-1);
		else if (key === "ArrowRight") store.switchMedia(1);
	};

	const renderMedia = (media, index) => {
		if (media.media_type === "video") {
			return media.platform === "youtube" ? (
				<iframe
					width="100%"
					height="100%"
					src={
						"https://www.youtube.com/embed/" +
						media.hash +
						"?rel=0&fs=0&controls=0&showinfo=0"
					}
					id={"iframe" + index}
					title="Video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
					className={classes.mediaContainerVideo}
					// loading={"lazy"}
				/>
			) : (
				<iframe
					width="100%"
					height="100%"
					src={
						"https://player.vimeo.com/video/" +
						media.hash +
						"?rel=0&fs=0&controls=0&showinfo=0"
					}
					id={"iframe" + index}
					title="Video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
					className={classes.mediaContainerVideo}
					// loading={"lazy"}
				/>
			);
		}
		if (!fullScreen) {
			media.value = media.value.replace("w_600", "w_1800");
		}
		return (
			<LazyLoad>
				<img
					className={classes.mediaContainerPic}
					src={media.original || media.value}
					// loading={"lazy"}
				/>
			</LazyLoad>
		);
	};

	const resetVideo = (index) => {
		const video = document.getElementById("iframe" + index);
		if (!video) return;

		const src = video.src;
		video.src = src;
	};

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={true}
			maxWidth={false}
			fullWidth={true}
			open={store.show}
			onClose={() => {
				store.onClose(true);
			}}
			aria-labelledby="Image view"
			aria-describedby="Image view"
			onKeyDown={handleKeydown}
			classes={{
				paper: classes.mediaPaper,
			}}
		>
			<DialogTitle className={classes.mediaDialogHeader} disableTypography>
				{/* 			<IconButton style={{ color: "#FFF" }} aria-label="share">
					<ShareIcon />
				</IconButton> */}
				<Box width={48} />

				<MobileStepper
					classes={{ root: classes.mobileStepper }}
					steps={store.media.length}
					position="static"
					variant="text"
					activeStep={store.selectedIndex}
				/>

				<IconButton
					className={classes.outlineButton}
					aria-label="close"
					onClick={() => {
						store.onClose(true);
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<div className={classes.mediaContainerBox}>
					<div className={classes.mediaContainerPicBox}>
						<Box
							height={"calc(100% - 112px)"}
							top={112}
							width={{ sm: "calc(100% - 236px)", md: "calc(100% - 330px)" }}
							position={"relative"}
							margin={"0px auto"}
						>
							<Box height={"100%"}>
								<Box display={"flex"} flexDirection={"column"} height={"100%"}>
									<Box
										flex={"1 1 auto"}
										maxHeight="calc(100% - 112px) !important"
										position={"relative"}
									>
										<SwipeableViews
											axis={theme.direction === "rtl" ? "x-reverse" : "x"}
											index={store.selectedIndex}
											onChangeIndex={handleStepChange}
											enableMouseEvents
											style={{
												width: "100%",
												height: "100%",
											}}
											containerStyle={{
												width: "100%",
												height: "100%",
											}}
										>
											{store.media.length > 0 &&
												store.media.map((media, index) => (
													<div className={classes.mediaItem} key={index}>
														{renderMedia(media, index)}
													</div>
												))}
										</SwipeableViews>
									</Box>
									{!!store.media[store.selectedIndex] &&
										!!store.media[store.selectedIndex].caption && (
											<Hidden only="xs">
												<div className={classes.mediaCaption}>
													{store.media[store.selectedIndex].caption}
												</div>
											</Hidden>
										)}
								</Box>
							</Box>
						</Box>
					</div>

					<div className={classes.mediaContainerNav}>
						{store.selectedIndex !== 0 ? (
							<IconButton
								className={classes.whiteButton}
								color="primary"
								aria-label="Back"
								onClick={handleSwitchMedia(-1)}
							>
								<KeyboardArrowLeft />
							</IconButton>
						) : (
							<Box width={48} />
						)}
						{store.selectedIndex !== store.media.length - 1 ? (
							<IconButton
								className={classes.whiteButton}
								color="primary"
								aria-label="Next"
								onClick={handleSwitchMedia(1)}
							>
								<KeyboardArrowRight />
							</IconButton>
						) : (
							<Box width={48} />
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
});

export default ImageDialog;
